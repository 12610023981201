import { UserPermissions } from "@/types/UserPermissions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PermissionsSliceState = {
  permissions: UserPermissions[];
};

const initialPermissionsState: PermissionsSliceState = {
  permissions: [],
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState: initialPermissionsState,
  reducers: {
    setPermissions(state, action: PayloadAction<UserPermissions[]>) {
      state.permissions = action.payload;
    },
    clearPermissions(state) {
      state.permissions = [];
    }
  }
});

export const { setPermissions, clearPermissions } = permissionsSlice.actions;
export default permissionsSlice;

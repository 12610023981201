import { payrollPageStorageKey } from "@/config/constants/StorageKeys";
import { getData, saveData } from "@/utils/localStorage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PayrollPageSliceState = {
    id: number | null;
}

const initialPageState: PayrollPageSliceState = {
  id: getData<number | null>(payrollPageStorageKey),
}

const payrollPageSlice = createSlice({
  name: "page",
  initialState: initialPageState,
  reducers: {
    setSelectedPayrollPage(state, action: PayloadAction<number | null>) {
      state.id = action.payload;
      saveData(payrollPageStorageKey,action.payload)
    },
  },
});

export const { setSelectedPayrollPage } = payrollPageSlice.actions;
export default payrollPageSlice;

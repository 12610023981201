import React, { Fragment, useEffect, useState } from 'react'
import { Company, CompanyLogo, Company as CompanyType, CompanyUpdateRequest, CompanyUpdateSchema } from '@/types/Company'
import GenericApi from '@/api/genericApi'
import { CompanyQueryKey } from '@/config/constants/QueryKeys'
import { ApiRoutes } from '@/config/routes/ApiRoutes'
import { ApiResponse, ApiError } from '@/types/Api'
import { FormError, ValidationErrors } from '@/types/ValidationError'
import CustomLogger from '@/utils/CustomLogger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackBarAlert } from '@/hooks/useSnackbar'
import axios, { AxiosError, isAxiosError } from 'axios'
import { AdaptiveModal, LoadingSpinner } from '..'
import { CustomModalPosition } from '@/types/Modal'
import { setSelectedCompany } from '@/store/slices/companySlice'
import { RootState, useAppDispatch } from '@/store'
import { useSelector } from 'react-redux'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import FormField from '../CustomForm/FormField'
import { generateCompanyUpdateInputs } from '@/utils/generateInputs'
import { Currency } from '@/types/Currency'
import { getCountryByCurrency } from '@/utils/utils'
import { getUserToken } from '@/utils/localStorage'
import logo from '../../assets/logo1.png'

export interface CompanyProps {
    company: CompanyType
    currencyOptions: Currency[] | null
}

const CompanyItem: React.FC<CompanyProps> = ({ company, currencyOptions }) => {

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm<CompanyUpdateRequest>({
        resolver: zodResolver(CompanyUpdateSchema),
    });

    console.log({
        errors
    })

    const selectedCurrency = watch('currency');
    //Todo check if this works
    useEffect(() => {
        const country = getCountryByCurrency(currencyOptions ?? null, selectedCurrency);
        if (country) {
            setValue('country', country);
        }
    }, [selectedCurrency, setValue, getValues]);

    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const dispatch = useAppDispatch()

    const { selectedCompany } = useSelector((state: RootState) => state.company)

    const deleteCompanyMutation = useMutation<ApiResponse<{ message: string }>, ApiError<ValidationErrors>, { id: number }>(
        {
            mutationFn: (data) => GenericApi.delete<{ message: string }>(`${ApiRoutes.COMPANY}/${data.id}`),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                queryClient.invalidateQueries({ queryKey: [CompanyQueryKey] })
                showSnackBar("Company deleted", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error deleting company", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const handleDelete = (id: number) => {
        if(selectedCompany?.id === id){
            dispatch(setSelectedCompany(null))
        }
        deleteCompanyMutation.mutate({ id })
    }

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const handleSelect = (organization: Company) => {
        dispatch(setSelectedCompany(organization))
    }


    const selectedCompanyStyles = selectedCompany?.id === company?.id ? 'font-medium bg-green-600 bg:green-500 text-white px-3 py-2 rounded-md inline-flex items-center' : 'font-medium bg-primary-600 bg:primary-500 text-white px-3 py-2 rounded-md inline-flex items-center'
    const isSelected = selectedCompany?.id === company?.id

    const updateCompanyMutation = useMutation<ApiResponse<Company>, ApiError<ValidationErrors>, CompanyUpdateRequest>(
        {
            mutationFn: (data) => GenericApi.put<Company>(`${ApiRoutes.COMPANY}/${company.id}`, data),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                if(data?.id){
                    addCompanyLogo.mutate({
                        id: data.id,
                         // @ts-expect-error select the first file uploaded
                        logo: getValues('logo')?.[0] ?? null
                    })
                }
                setEditModalOpen(false)
                queryClient.invalidateQueries({ queryKey: [CompanyQueryKey] })
                showSnackBar("Company updated", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error updating company", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )


    const addCompanyLogo = useMutation<
    ApiResponse<CompanyLogo>,
    ApiError<ValidationErrors>,
    { logo: File | null, id: number }
  >({
    mutationFn: async ({ logo, id }) => {
      const formData = new FormData();
      formData.append("logo", logo!);
      const response = await axios.post<ApiResponse<CompanyLogo>>(
        `https://pandatax.ctrl13.ro/api/company/${id}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: ["company-logo"] });
      queryClient.invalidateQueries({ queryKey: [CompanyQueryKey] });
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
    },
  });



    const updateCompanyInputs = generateCompanyUpdateInputs(company, currencyOptions)

    const onSubmit = (data: CompanyUpdateRequest) => {
        updateCompanyMutation.mutate(data)
    }

    const inputStyle = (inputName: string) => {
        if(inputName === "multicurrency"){
            return "width-[15%] p-2" 
        }
        else if (inputName === "logo"){
            return "w-[50%] mt-1"
        }
       return "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" 
    }

    const companyLogo = company?.logo ? `https://pandatax.ctrl13.ro${company.logo}` : logo

    return (
        <Fragment>
            <tr
                className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
                <th
                    scope="row"
                    className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                    <img src={companyLogo} alt="logo" className='size-7' />
                </th>
                <td className="px-4 py-3">{company.name}</td>
                <td className="px-4 py-3">{company.tax_number}</td>
                <td className="px-4 py-3">{company.country}</td>
                <td className="px-4 py-3">{company.currency}</td>
                <td className="px-4 py-3 text-right font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <button onClick={() => handleSelect(company)} className={selectedCompanyStyles}>
                        {
                            isSelected ? 'Selected' : 'Select'
                        }
                    </button>
                </td>
                <td className="px-4 py-3 text-right">
                    <button
                        onClick={toggleEditModal}
                        className="font-medium text-green-400 dark:text-green-400 hover:underline"
                    >
                        Edit
                    </button>
                    <span className="mx-2">|</span>
                    <button
                        onClick={toggleDeleteModal}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                    >
                        Delete
                    </button>

                </td>
            </tr>


            <AdaptiveModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delete Company</h2>}
                position={CustomModalPosition.Center}
                footer={
                    <Fragment>
                        <button
                            onClick={() => handleDelete(company.id)}
                            className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
                        >
                            {
                                deleteCompanyMutation.isPending ? <LoadingSpinner variant='danger' /> : 'Delete'
                            }
                        </button>
                        <button
                            onClick={toggleDeleteModal}
                            className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            >
                <p className="text-gray-700 dark:text-gray-300">
                    Are you sure you want to delete this company?
                </p>
            </AdaptiveModal>


            <AdaptiveModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Edit Company</h2>}
                position={CustomModalPosition.Center}
            >
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        updateCompanyInputs.map((input) => (
                            <FormField<CompanyUpdateRequest>
                                key={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                required={input.required}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle={inputStyle(input.name)}
                                label={input.label}
                                options={input.options}
                                defaultValue={input.defaultValue}
                                readonly={input?.readonly ?? false}
                                register={register}
                                error={errors[input.name as keyof CompanyUpdateRequest]}
                            />
                        ))
                    }
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            updateCompanyMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Edit Company"
                        }
                    </button>
                </form>
            </AdaptiveModal>
        </Fragment>
    )
}

export default CompanyItem

import GenericApi from "@/api/genericApi";
import { ApiResponse } from "@/types/Api";
import { useQuery } from "@tanstack/react-query";
import React from "react";


interface CheckoutProps {
  userEmail: string;
  csrfToken: string;
}

const Checkout: React.FC = () => {
   return (
    <Plans />
  );
};

export default Checkout;

// const features = [
//   "watch all movies",
//   "movie length [all]",
//   "receive mailing lists",
//   "track your progress",
//   "1GB hosting space to upload your work",
//   "new movies every month",
//   "private movies [included with membership]",
//   "profile appear in the top members page",
//   "movie premier",
//   "compete with other members",
//   "play games",
//   "ranking system and achievements",
//   "watch movies on your mobile"
// ];


const Plans = () => {
  const { data: userDetails } = useQuery<ApiResponse<CheckoutProps>>({
    queryKey: ["checkout"],
    queryFn: async () => GenericApi.get<CheckoutProps>("/billing/subscribe"),
  });

  // type CreateCheckoutSessions = {
  //   userEmail: string;
  //   csrfToken: string;
  //   priceLookupKey: string;
  // };

  // type CheckoutUrl = {
  //   checkout_url: string;
  // };

  // const priceLookupKey = "standard_monthly";
  // const createCheckoutSessionRoute = `/billing/create-checkout-session?price_lookup_key=${priceLookupKey}`;

  // const createCheckoutSession = useMutation<
  //   ApiResponse<CheckoutUrl>,
  //   ApiError<ValidationErrors>,
  //   CreateCheckoutSessions
  // >({
  //   mutationFn: (data) =>
  //     GenericApi.post<CheckoutUrl>(createCheckoutSessionRoute, data),
  //   onSuccess: async ({ data }) => {
  //     console.log("Checkout session created:", data);
  //     window.location.href = data?.checkout_url ?? RoutesPathList.Billing;
  //   },
  // });

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (userDetails?.data?.userEmail && userDetails?.data?.csrfToken) {
  //     const formData = {
  //       userEmail: userDetails.data.userEmail,
  //       csrfToken: userDetails.data.csrfToken,
  //       priceLookupKey: "standard_monthly",
  //     };
  //     console.log("formData", formData);
  //     createCheckoutSession.mutate(formData);
  //   } else {
  //     console.error("User email or CSRF token is missing");
  //   }
  // };

  console.log(userDetails?.data, "userDetails");
  return (
    <>
      {/* <div className="main">
      <div className="plan premium">
        <h1>Premium</h1>
        <ul>
          {features.map((feature, index) => (
            <li className="plans-elements" key={index}>
              <span></span>{feature}
            </li>
          ))}
          <li className="plans-elements-bottom">
          <form className="checkout-form w-60" onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value={userDetails?.data?.csrfToken}
          />
          <input
            type="hidden"
            name="price_lookup_key"
            value="standard_monthly"
          />
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
            id="checkout-and-portal-button"
            type="submit"
          >
            Checkout
          </button>
        </form>
          </li>
        </ul>
      </div>
    </div> */}
    </>
  );
};

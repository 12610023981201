import React, { useEffect, useState } from 'react';
import GenericApi from '@/api/genericApi';
import FormField from '@/components/CustomForm/FormField';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { RoutesPathList } from '@/config/routes/Routes';
import { ApiResponse, ApiError } from '@/types/Api';
import { CreateUserCompany, CreateUserCompanySchema, User } from '@/types/User';
import { FormError, ValidationErrors } from '@/types/ValidationError';
import CustomLogger from '@/utils/CustomLogger';
import { formatError } from '@/utils/formatError';
import { generateCreateUserInputs } from '@/utils/generateInputs';
import { wait } from '@/utils/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CreateCompanyUser: React.FC = () => {
    const navigate = useNavigate();
    // const { showSnackBar } = useSnackBarAlert();
    const [searchParams] = useSearchParams();
    const [emailFromUrl, setEmailFromUrl] = useState<string | null>(null);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm<CreateUserCompany>({
        resolver: zodResolver(CreateUserCompanySchema),
    });

    useEffect(() => {
        setEmailFromUrl(searchParams.get('email'));
        setValue('email', searchParams.get('email') ?? '');
    }, [searchParams, setValue])
    
    console.log(emailFromUrl);


    const createUserInputs = generateCreateUserInputs();

    const createUserMutation = useMutation<ApiResponse<User>, ApiError<ValidationErrors>, CreateUserCompany>(
        {
            mutationFn: (data) => GenericApi.post<User>(ApiRoutes.CREATE_USER, data),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data);
                CustomLogger.log('User created successfully');
                await wait(1000);
                navigate(RoutesPathList.Login, { replace: true });
            },
            onError: async ({ error }) => {
                CustomLogger.error(error);
                handleAxiosError(error);
            },
        }
    );

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        const errorMessage = "An error occurred while creating the user";
        if (isAxiosError<FormError>(error)) {
            setError('root', {
                type: 'manual',
                message: error.response?.data?.detail ?? errorMessage
            });
            return;
        }

        if (isAxiosError<ValidationErrors>(error)) {
            setError('root', {
                type: 'manual',
                message: formatError(error, errorMessage)
            });
            return;
        }

        setError('root', {
            type: 'manual',
            message: error?.message ?? errorMessage
        });
    };

    const onSubmit = (data: CreateUserCompany) => {
        createUserMutation.mutate(data);
    };

    return (
        <div className="bg-gray-50 dark:bg-gray-900 h-[100dvh]">
            <div className="w-full flex justify-center items-center px-4 py-8 mx-auto">
                <div className="w-[40rem] p-6 mt-16 bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                    <h2 className="mb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Create Company User
                    </h2>
                    {createUserMutation.isError && (
                        <div className="alert alert-error">
                            {errors.root?.message}
                        </div>
                    )}
                    {createUserMutation.isSuccess && (
                        <div className="alert alert-success">
                            User created successfully
                        </div>
                    )}
                    <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSubmit(onSubmit)}>
                        {
                            createUserInputs.map((input, index) => (
                                <FormField<CreateUserCompany>
                                    key={index}
                                    label={input.label}
                                    required={input.required}
                                    error={errors[input.name as keyof CreateUserCompany]}
                                    name={input.name}
                                    placeholder={input.placeholder}
                                    register={register}
                                    type={input.type}
                                    defaultValue={emailFromUrl ?? ''}
                                    autocomplete='new-password'
                                    valueAsNumber={input.valueAsNumber}
                                    inputStyle='bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                />
                            ))
                        }
                        <button
                            type="submit"
                            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                            {
                                createUserMutation.isPending ?
                                    <div className='flex justify-center items-center gap-4'>
                                        Creating User
                                        <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg>
                                    </div>
                                    : 'Create User'
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateCompanyUser;

import React, { useState } from "react";
import LoadingPage from "./LoadingPage";
import FallbackPage from "./Fallback";
import GenericApi from "@/api/genericApi";
import { PaySlipQueryKey, SalaryQueryKey } from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { ApiError, ApiResponse } from "@/types/Api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdaptiveModal } from "@/components";
import { CustomModalPosition } from "@/types/Modal";
import CustomLogger from "@/utils/CustomLogger";
import FormField from "@/components/CustomForm/FormField";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ValidationErrors, FormError } from "@/types/ValidationError";
import { AxiosError, isAxiosError } from "axios";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { generateSalaryInputs } from "@/utils/generateInputs";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/store";
import SalaryItem from "@/components/Salary/SalaryItem";
import { SalaryType, CreateSalaryType, CreateSalaryTypeSchema } from "@/types/Salary";
import { setSelectedPayrollPage } from "@/store/slices/payrollPageSlice";

const Salary: React.FC = () => {
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateSalaryType>({ 
    resolver: zodResolver(CreateSalaryTypeSchema),
  });

  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const [salaryModal, setSalaryModal] = useState(false); //schimbare ...Modals ***

  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const { selectedCompany } = useSelector((state: RootState) => state.company);

  const route = `${ApiRoutes.SALARY}/company/${selectedCompany?.id}`; 

  const {
    data: salaries, //redenumire ***
    isLoading: isLoadingSalaries, //redenumire ***
    error,
  } = useQuery<ApiResponse<SalaryType[]>>({  //schimbare tipuri ***
    queryFn: async () =>
      GenericApi.get<SalaryType[]>(route), //schimbare tipuri ***
    queryKey: [SalaryQueryKey], //adaugat + schimbare chei ***
  });

  console.log(salaries?.data); //modificat ***


  const salaries_inputs = generateSalaryInputs(salaries?.data ?? null);  //schimbare functie si denumire variabila  ***

  

  const addSalariesMutation = useMutation<  
    ApiResponse<SalaryType>,  //schimbare tipuri 
    ApiError<ValidationErrors>,
    CreateSalaryType //schimbare tipuri 
  >({
    mutationFn: (data) =>
      GenericApi.post<SalaryType>(ApiRoutes.SALARY, data),  //schimbare tipuri + ruta noua 
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      reset();
      setSalaryModal(false);  //modificat 
      queryClient.invalidateQueries({ queryKey: [SalaryQueryKey] }); //adaugat + schimbare chei 
      queryClient.invalidateQueries({ queryKey: [PaySlipQueryKey] }); //adaugat + schimbare chei
      showSnackBar(
        "Salary created",  //schimb nume 
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar(
        "Error creating Salary",  //schimbat nume 
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error)) {
      console.log(error);
      return;
    }

    if (isAxiosError<ValidationErrors>(error)) {
      console.log(error);
      return;
    }

    console.log(error);
  };

  const onSubmit = (data: CreateSalaryType) => {

    if(!selectedCompany) {
      showSnackBar(
        "Please select a company",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    const payload = {
      ...data,
      company_id: selectedCompany?.id,
    };

    CustomLogger.log(data);
    addSalariesMutation.mutate(payload);  //schimbat cu var corecta 
  };

  const renderOrganizationBySearchValue = () => {
    if (searchValue) {
      const filteredSalary = salaries?.data?.filter( //modificat 
        (salaries) =>  //schimbat nume 
          salaries.first_name  //schimbat nume 
            .toLowerCase()
            .includes(searchValue.toLowerCase())
            ||
          salaries.last_name  //schimbat nume 
            .toLowerCase()
            .includes(searchValue.toLowerCase())  
      );
      return filteredSalary?.map((salaries) => (    //schimbat param functie + modificat component 
        <SalaryItem     //modificat 
          onClick={() => dispatch(setSelectedPayrollPage(salaries.id))}  //modificat
          salaries={salaries}  //modificat 
          key={salaries.id}  //modificat 
        />
      ));
    }

    return salaries?.data?.map((salaries) => (      //schimbat param functie + modificat component 
      <SalaryItem   //modificat 
        onClick={() => dispatch(setSelectedPayrollPage(salaries.id))}  //mod
        salaries={salaries}  //modificat 
        key={salaries.id}  //modificat 
      />
    ));
  };

  const toggleSalariesModal = () => {
    setSalaryModal(!salaryModal);   //modificat 
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const onRetry = () => {
    window.location.reload();
  };

  if (isLoadingSalaries)    //modificat 
    return (
      <div className="mt-[4rem]">
        <LoadingPage />
      </div>
    );

  const fetchError = error || salaries?.error;   //modificat 

  if (fetchError) {
    console.error(fetchError);
    return <FallbackPage onRetry={onRetry} />;
  }

  if (!salaries?.data) {
    console.error("Data is undefined");
    return <FallbackPage onRetry={onRetry} />;
  }

  const inputStyle = (inputName:string) => {
    if(inputName === "salary_13th"){
      return "w-5 mt-1"
    }
    return "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
  }

  return (
    <div className="bg-gray-50 mt-[4rem] dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div className="w-full md:w-full flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3">
            <div className="flex justify-center items-center gap-4">
              <form className="flex items-center">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="2"
                        d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="search"
                    id="default-search"
                    className="block w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search"
                    required
                  />
                </div>
              </form>
              <button
                onClick={toggleFilter}
                id="filterDropdownButton"
                data-dropdown-toggle="filterDropdown"
                className="flex relative items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clip-rule="evenodd"
                  />
                </svg>
                Filter
                <svg
                  className="-mr-1 ml-1.5 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  />
                </svg>
                {openFilter ? (
                  <div className="z-[9999] absolute top-full w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                    <h6 className="mb-3 text-sm text-start font-medium text-gray-900 dark:text-white">
                      Date
                    </h6>
                    <ul
                      className="space-y-2 text-sm"
                      aria-labelledby="dropdownDefault"
                    >
                      <li className="flex items-center">
                        <input
                          id="apple"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label
                          htmlFor="apple"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                        >
                          Ascending
                        </label>
                      </li>
                      <li className="flex items-center">
                        <input
                          id="fitbit"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label
                          htmlFor="fitbit"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                        >
                          Descending
                        </label>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>

            <button
              onClick={toggleSalariesModal}
              data-modal-toggle="createCompanyModal"
              type="button"
              className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              <svg
                className="h-3.5 w-3.5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                />
              </svg>
              Add Employee 
                {/* modoficart  */}
            </button> 
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs w-full text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 space-x-4">
                <th scope="col" className="px-4 py-3 text-left">
                  First Name
                </th>
                <th scope="col" className="px-4 py-3">
                  Last Name
                </th>
                <th scope="col" className="px-4 py-3 text-left">
                  Gross Salary
                </th>
                <th scope="col" className="px-4 py-3 text-left">
                  ID NO.
                </th>
                <th scope="col" className="px-4 py-3 text-left">
                  Tax ID NO.
                </th>
                <th scope="col" className="px-4 py-3 text-left">
                  SI NO.
                </th>
                <th scope="col" className="px-4 py-3 text-left">
                  Hired At
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  13th Salary
                </th>
                <th scope="col" className="px-4 py-3 text-right">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{renderOrganizationBySearchValue()}</tbody>
          </table>
        </div>
      </div>

      <AdaptiveModal
        isOpen={salaryModal}  //modificat 
        onClose={() => setSalaryModal(false)}  //modificat 
        position={CustomModalPosition.Center}
        title={<p className="dark:text-white">Add Employee</p>}  //modificat 
      >
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {salaries_inputs.map((input) => (  //modificat 
            <FormField<CreateSalaryType>  //modificat 
              key={input.name}
              type={input.type}
              placeholder={input.placeholder}
              name={input.name}
              required={input.required}
              valueAsNumber={input.valueAsNumber}
              inputStyle={inputStyle(input.name)}
              label={input.label}
              register={register}
              error={errors[input.name as keyof CreateSalaryType]}   //modificat
            /> 
          ))}
          <button
            type="submit"
            className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            {addSalariesMutation.isPending ? (
              <svg
                className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              ></svg>
            ) : (
              "Create Salary"
            )}  
            {/* modificat  */}
          </button>
        </form>
      </AdaptiveModal>
    </div>
  );
};

export default Salary;  //modificat 

import { ZodSchema, z } from "zod";

export type DoubleEntryAccount = {
    id: number;
    company: number;
    type: string;
    code: string;
    name: string;
    subcategory: string
}

export type DoubleEntryAccountWithClient = {
    id: number;
    company: number;
    type: string;
    code: string;
    name: string;
    subcategory: string
    client: number
}


export type DoubleEntryAccountView = {
    id: number;
    type: string;
    code: string;
    name: string;
    company?: number;
    subcategory: string
}

export type DoubleEntryAccountRequest = {
    type: string;
    code: string;
    name: string;
    company?: number;
    subcategory: string
}

export type DoubleEntryAccountUpdate = {
    type: string;
    code: string;
    name: string;
    subcategory: string
}


export const DoubleEntrySchemaUpdate: ZodSchema<DoubleEntryAccountUpdate> = z.object({
    type: z.string(),
    code: z.string(),
    name: z.string(),
    subcategory: z.string()
})

export const DoubleEntryCreateSchema: ZodSchema<DoubleEntryAccountRequest> = z.object({
    type: z.string(),
    code: z.string(),
    name: z.string(),
    company: z.number().optional(),
    subcategory: z.string()
})


export type DoubleEntryAccountItem = {
    id: number;
    company: number; 
    account: number;
    item: number; 
    type: string;
};

import GenericApi from "@/api/genericApi";
import { AdaptiveModal } from "@/components";
import Loader from "@/components/Loader";
import {
  CurrencyQueryKey,
  DoubleEntryAccountQueryKey,
  InvoiceQueryKey,
  JournalEntryQueryKey,
} from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { RootState } from "@/store";
import { ApiResponse, ApiError } from "@/types/Api";
import { Currency } from "@/types/Currency";
import { DoubleEntryAccountWithClient } from "@/types/DoubleEntry";
import {
  InvoiceCreate,
  InvoiceType,
  InvoiceTypeGetJournal,
} from "@/types/Invoice";
import {
  DoubleEntryJournal,
  DoubleEntryJournalInvoiceRequest,
} from "@/types/Journal";
import { CustomModalPosition } from "@/types/Modal";
import { ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { getUserToken } from "@/utils/localStorage";
import { getVal } from "@/utils/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";

type Client = {
  id: number;
  name: string;
  country: string;
  address: string;
  vat_number: string;
};

interface ChartAccountClientResponse {
  client: Client;
  id: number;
  company: number;
  code: string;
  type: string;
  name: string;
  subcategory: string;
}

const Invoice = () => {
  const [postModal, setPostModal] = useState(false);
  const [creditCode, setCreditCode] = useState("");
  const [loadingInvoiceId, setLoadingInvoiceId] = useState<number | null>(null);
  const [previewInvoiceId, setPreviewInvoiceId] = useState<boolean>(false);
  const [postId,setPostId] = useState(0)

  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const selectedCompany = useSelector(
    (state: RootState) => state.company.selectedCompany
  );
  // const routePage = selectedCompany ? `${ApiRoutes.PAGES}/company/${selectedCompany.id}` : '';
  const routeAccount = `/double-entry-account/company/${selectedCompany?.id}`;

  // const {
  //   data: pages,
  // } = useQuery<ApiResponse<Page[]>>({  //schimbare tipuri ***
  //   queryFn: async () =>
  //     GenericApi.get<Page[]>(routePage), //schimbare tipuri ***
  //   queryKey: [PagesQueryKey], //adaugat + schimbare chei ***
  // });

  const { data: currencies } = useQuery<ApiResponse<Currency[]>>({
    queryKey: [CurrencyQueryKey],
    queryFn: async () => GenericApi.get<Currency[]>(ApiRoutes.CURRENCIES),
  });

  const { data: invoicesData } = useQuery<ApiResponse<InvoiceTypeGetJournal[]>>(
    {
      queryKey: [InvoiceQueryKey],
      queryFn: async () =>
        GenericApi.get<InvoiceTypeGetJournal[]>(invoiceRoute),
    }
  );

  const { data: doubleEntry } = useQuery<ApiResponse<DoubleEntryAccountWithClient[]>>({
    queryKey: [DoubleEntryAccountQueryKey],
    queryFn: async () => GenericApi.get<DoubleEntryAccountWithClient[]>(routeAccount),
  });


  const clientRoute = `/invoice-client/${selectedCompany?.id}`;

  const { data: clients } = useQuery<ApiResponse<ChartAccountClientResponse[]>>(
    {
      queryKey: ["ClientQueryKey"],
      queryFn: async () =>
        GenericApi.get<ChartAccountClientResponse[]>(clientRoute),
    }
  );

  const [invoice, setInvoice] = useState<InvoiceCreate>({
    company_id: selectedCompany?.id || 0,
    document_number: "",
    due_date: "",
    subject: "",
    client_id: "",
    closing_remark: "",
    request_of_payment: false,
    currency: "EUR",
    items: [
      {
        invoice_id: 0,
        description: "",
        quantity: 0,
        price: 0,
        vat_rate: 0,
        out_of_pocket: false,
      },
    ],
  });

  const invoiceRoute = `/invoice/${selectedCompany?.id}`;

  const addInvoiceMutation = useMutation<
    ApiResponse<InvoiceType>,
    ApiError<ValidationErrors>,
    InvoiceCreate
  >({
    mutationFn: (data) => GenericApi.post<InvoiceType>(invoiceRoute, data),
    onSuccess: ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [InvoiceQueryKey] });
      showSnackBar(
        "Invoice created successfully",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
      resetForm();
    },
    onError: ({ error }) => {
      CustomLogger.error(error);
      showSnackBar(
        "Error creating invoice",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const resetForm = () => {
    setInvoice({
      company_id: selectedCompany?.id || 0,
      document_number: "",
      due_date: "",
      subject: "",
      client_id: "",
      closing_remark: "",
      request_of_payment: false,
      currency: "EUR",
      items: [
        {
          invoice_id: 0,
          description: "",
          quantity: 0,
          price: 0,
          vat_rate: 0,
          out_of_pocket: false,
        },
      ],
    });
  };

  // const handleInputChange = (field: keyof Omit<InvoiceCreate, "items">, value: string) => {
  //   setInvoice((prevInvoice) => ({
  //     ...prevInvoice,
  //     [field]: value,
  //   }));
  // };

  const handleInputChange = (
    field: keyof Omit<InvoiceCreate, "items">,
    value: string | boolean
  ) => {
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      [field]: field === "request_of_payment" ? Boolean(value) : value,
    }));
  };

  const handleDetailChange = (
    detailIndex: number,
    field: keyof InvoiceCreate["items"][number],
    value: string | number | boolean
  ) => {
    setInvoice((prevInvoice) => {
      const newItems = [...prevInvoice.items];
      const detailField = newItems[detailIndex];

      if (field === "quantity" || field === "price") {
        detailField[field] = Number(value);
      } else if (field === "out_of_pocket") {
        detailField[field] = Boolean(value);
      } else {
        // @ts-expect-error => This is a string field
        detailField[field] = value as string;
      }

      return {
        ...prevInvoice,
        items: newItems,
      };
    });
  };

  const addDetail = () => {
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      items: [
        ...prevInvoice.items,
        {
          invoice_id: 0,
          description: "",
          quantity: 0,
          price: 0,
          vat_rate: 0,
          out_of_pocket: false,
        },
      ],
    }));
  };

  const removeDetail = (detailIndex: number) => {
    setInvoice((prevInvoice) => {
      const newItems = [...prevInvoice.items];
      newItems.splice(detailIndex, 1);
      return {
        ...prevInvoice,
        items: newItems,
      };
    });
  };

  // const fileName = "invoice.pdf"
  const invoiceRoutePdf = `/invoice/${selectedCompany?.id}`;

  //   const addInvoiceDownload = useMutation<ApiResponse<BlobPart>,
  //   ApiError<ValidationErrors>,
  //   {id: number}
  // >({
  //   mutationFn: (data) => GenericApi.post<BlobPart>(`${invoiceRoutePdf}/${data.id}/pdf`, data, 'blob'),
  //   onSuccess: async ({ data }) => {
  //     CustomLogger.log(data);
  //     if(data){
  //       const linkUrl = window.URL.createObjectURL(new Blob([data]));
  //       const link = document.createElement('a');
  //       link.href = linkUrl;
  //       link.setAttribute('download', fileName);
  //       document.body.appendChild(link);
  //       link.click();
  //     }

  //   },
  //   onError: async ({ error }) => {
  //     CustomLogger.error(error);
  //   },
  // });

  const downloadInvoicePdf = async (invoiceId: number) => {
    try {
      setLoadingInvoiceId(invoiceId);
      const response = await axios.post(
        `https://pandatax.ctrl13.ro/api${invoiceRoutePdf}/${invoiceId}/pdf`,
        // `http://192.168.1.155/api${invoiceRoutePdf}/${invoiceId}/pdf`,
        {},
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const linkUrl = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = linkUrl;
      downloadLink.download = `invoice_${invoiceId}.pdf`;
      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      setTimeout(() => window.URL.revokeObjectURL(linkUrl), 1000);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setLoadingInvoiceId(null);
    }
  };

  const previewInvoicePdf = async () => {
    try {
      setPreviewInvoiceId(true);
      const response = await axios.post(
        `https://pandatax.ctrl13.ro/api${invoiceRoutePdf}/preview`,
        //  `http://192.168.1.155/api${invoiceRoutePdf}/preview`,
        invoice,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const linkUrl = window.URL.createObjectURL(blob);

      window.open(linkUrl, "_blank");

      setTimeout(() => window.URL.revokeObjectURL(linkUrl), 1000);
    } catch (error) {
      console.error("Error previewing PDF:", error);
    } finally {
      setPreviewInvoiceId(false);
    }
  };

  // const handleDownloadInvoice = (invoiceId: number) => {
  //   addInvoiceDownload.mutate({id:invoiceId})
  // }

  const invoiceDelete = useMutation<
    ApiResponse<{ message: string }>,
    ApiError<ValidationErrors>,
    { id: number }
  >({
    mutationFn: (data) =>
      GenericApi.delete<{ message: string }>(`${invoiceRoute}/${data.id}`),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [InvoiceQueryKey] });
      showSnackBar(
        "Invoice deleted",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      showSnackBar(
        "Error deleting invoice",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const handleDelete = (id: number) => {
    invoiceDelete.mutate({ id });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(invoice, "payload");

    addInvoiceMutation.mutate({
      ...invoice,
      company_id: selectedCompany?.id || 0,
    });
  };

  const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    postJournalMutation(postId)
  }



  const renderInvoiceEntries = () => {
    return invoicesData?.data?.map((invoice) => (
      <tr key={invoice.id}>
        <td className="px-6 py-3 text-center">{invoice.document_number}</td>
        <td className="px-6 py-3 text-center">{invoice.due_date}</td>
        <td className="px-6 py-3 text-center">{invoice.subject}</td>
        <td className="px-6 py-3 text-center">{invoice.sub_total}</td>
        <td className="px-6 py-3 text-center">{invoice.total}</td>
        <td className="px-4 py-3 text-center flex gap-2 justify-center">
          <button
            onClick={() => downloadInvoicePdf(invoice.id)}
            className="font-medium text-green-400 dark:text-green-400 hover:underline"
          >
            {loadingInvoiceId === invoice.id ? "Downloading..." : "Download"}
          </button>
          <span>|</span>
          <button
            onClick={() => handleDelete(invoice.id)}
            className="font-medium text-red-600 dark:text-red-500 hover:underline"
          >
            Delete
          </button>
          <span>|</span>
          <button
            onClick={() => {
              toggleModal()
              setPostId(invoice.client)
            }}
            className="font-medium text-blue-500 dark:text-blue-400 hover:underline"
          >
            Post
          </button>
        </td>
      </tr>
    ));
  };

  const addJournalMutation = useMutation<
    ApiResponse<DoubleEntryJournal>,
    ApiError<ValidationErrors>,
    DoubleEntryJournalInvoiceRequest
  >({
    mutationFn: (data) => {
      const journalInvoiceRoute = `/invoice/create-journal/${selectedCompany?.id}/${data.id}`;
      return GenericApi.post<DoubleEntryJournal>(journalInvoiceRoute, data);
    },
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [JournalEntryQueryKey] });
      setPostModal(false)
      showSnackBar(
        "Journal created",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      showSnackBar(
       "The account code is incorrect or is used in multiple accounts.",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const toggleModal = () => {
    setPostModal(!postModal); //modificat
  };
  

  
  
  
  const postJournalMutation = (invoiceClientId: number) => {

    console.log({
      "clients": clients?.data?.slice(0,5),
      "doubleEntry": doubleEntry?.data?.slice(0,5),
      "invoiceCliendId": invoiceClientId
    })
    

    const clientWithCode = clients?.data?.find((client) => client.client.id === invoiceClientId);

    const ChartOfAccount = doubleEntry?.data?.find(
      (account) => account.code === clientWithCode?.code
    );
    console.log({
      clientWithCode,
      ChartOfAccount,
    })
    const invoiceId = invoicesData?.data?.find((inv) => inv.client === invoiceClientId)?.id

    const payload = {
      id: invoiceId,
      company_id: selectedCompany?.id,
      account_id: ChartOfAccount?.id,
    };

    console.log(payload, "payload");



    addJournalMutation.mutate({
      id: invoiceId ?? 0,
      company_id: selectedCompany?.id ?? 0,
      account_id: ChartOfAccount?.id ?? 0,
      credit_code: creditCode,
    });
  };

  return (
    <section className="mt-24 mb-24">
      <div className="ml-20 mb-16">
        <h1 className="mb-8 text-xl font-bold text-gray-900 dark:text-white border-b-2 border-[#E7E7E7]">
          Invoice
        </h1>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <div className="flex gap-4 mb-2 items-center">
              <label
                htmlFor="documentNumber"
                className="w-[20%] dark:text-white"
              >
                Document Number
              </label>
              <input
                required
                type="text"
                name="document_number"
                value={invoice.document_number}
                onChange={(e) =>
                  handleInputChange("document_number", e.target.value)
                }
                placeholder="Document Number"
                className="border rounded p-2"
              />
            </div>
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="dueDate" className="w-[20%] dark:text-white">
                Due Date
              </label>
              <input
                required
                type="date"
                name="due_date"
                value={invoice.due_date}
                onChange={(e) => handleInputChange("due_date", e.target.value)}
                placeholder="Due Date"
                className="border rounded p-2"
              />
            </div>
            <div className="flex gap-4 mb-1 items-center">
              <label htmlFor="subject" className="w-[20%] dark:text-white">
                Subject
              </label>
              <input
                required
                type="text"
                name="subject"
                value={invoice.subject}
                onChange={(e) => handleInputChange("subject", e.target.value)}
                placeholder="Subject"
                className="border rounded p-2"
              />
            </div>
          </div>

          <div className="flex gap-4 mb-2 items-center">
            <label htmlFor="subject" className="w-[20%] dark:text-white">
              Client
            </label>
            <select
              required
              value={invoice.client_id}
              onChange={(e) => handleInputChange("client_id", e.target.value)}
              style={{ width: "9.375rem" }}
              className="border rounded p-2"
            >
              <option value="" disabled>
                Select Client
              </option>
              {clients?.data?.map((client) => (
                <option key={client.client.id} value={client.client.id}>
                  {client.client.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-4 mb-2 items-center">
            <label htmlFor="subject" className="w-[20%] dark:text-white">
              Currency
            </label>
            <select
              required
              value={invoice.currency}
              onChange={(e) => handleInputChange("currency", e.target.value)}
              style={{ width: "9.375rem" }}
              className="border rounded p-2"
            >
              <option value="" disabled>
                Currency
              </option>
              {currencies?.data?.map((currency) => (
                <option key={currency.name} value={currency.currency}>
                  {currency.currency}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-4 mb-2 items-center">
            <label htmlFor="subject" className="w-[20%] dark:text-white">
              Closing Remark
            </label>
            <input
              required
              type="text"
              name="closing_remark"
              value={invoice.closing_remark}
              onChange={(e) =>
                handleInputChange("closing_remark", e.target.value)
              }
              placeholder="Closing Remark"
              className="border rounded p-2"
            />
          </div>

          <div className="flex gap-4 mb-2 items-center">
            <label
              htmlFor="request_of_payments"
              className="w-[20%] dark:text-white"
            >
              Request of Payments
            </label>
            <input
              type="checkbox"
              checked={invoice.request_of_payment}
              onChange={(e) =>
                handleInputChange(
                  "request_of_payment",
                  String(e.target.checked)
                )
              }
              className=" border-gray-300 text-gray-900 sm:text-sm block w-5 p-1 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>

          <h3 className="mt-5 text-lg font-bold border-b-2 border-[#E7E7E7] dark:text-white">
            Details
          </h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-center dark:text-white">
                  Description
                </th>
                <th className="px-6 py-3 text-center dark:text-white">
                  Quantity
                </th>
                <th className="px-6 py-3 text-center dark:text-white">Rate</th>
                <th className="px-6 py-3 text-center dark:text-white">VAT %</th>
                <th className="px-6 py-3 text-center dark:text-white">
                  Out of pocket expenses
                </th>
                <th className="px-6 py-3 text-center dark:text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {invoice.items.map((detail, detailIndex) => (
                <tr key={detailIndex}>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <input
                      required
                      type="text"
                      value={detail.description}
                      onChange={(e) =>
                        handleDetailChange(
                          detailIndex,
                          "description",
                          e.target.value
                        )
                      }
                      placeholder="Description"
                      className=" px-6 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <input
                      required
                      type="number"
                      value={getVal(detail.quantity)}
                      onChange={(e) =>
                        handleDetailChange(
                          detailIndex,
                          "quantity",
                          Number(e.target.value) || 0
                        )
                      }
                      placeholder="Quantity"
                      className="bg-gray-50 border dark:text-[#9CA3AF] border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <input
                      required
                      type="number"
                      value={getVal(detail.price)}
                      onChange={(e) =>
                        handleDetailChange(
                          detailIndex,
                          "price",
                          Number(e.target.value) || 0
                        )
                      }
                      placeholder="Rate"
                      className="bg-gray-50 dark:text-[#9CA3AF] border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <input
                      required
                      type="number"
                      value={
                        detail.out_of_pocket
                          ? getVal(0)
                          : getVal(detail.vat_rate)
                      }
                      onChange={(e) =>
                        handleDetailChange(
                          detailIndex,
                          "vat_rate",
                          detail.out_of_pocket ? 0 : e.target.value
                        )
                      }
                      placeholder="VAT"
                      readOnly={detail.out_of_pocket}
                      className="bg-gray-50 border dark:text-[#9CA3AF] border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td className="flex justify-center items-center mt-3 px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <input
                      type="checkbox"
                      checked={detail.out_of_pocket}
                      onChange={(e) =>
                        handleDetailChange(
                          detailIndex,
                          "out_of_pocket",
                          e.target.checked
                        )
                      }
                      className=" border-gray-300 dark:text-[#9CA3AF] text-gray-900 sm:text-sm block w-5 p-1 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                    <button
                      type="button"
                      className="text-red-600 hover:text-red-900"
                      onClick={() => removeDetail(detailIndex)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex gap-5 mt-4">
            <button
              type="button"
              onClick={addDetail}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Add Entry
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              {addInvoiceMutation.isPending ? <Loader /> : "Create Invoice"}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
              onClick={previewInvoicePdf}
            >
              {previewInvoiceId ? <Loader /> : "Preview Invoice"}
            </button>
          </div>
        </form>
      </div>

      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-4 py-3 text-center">Document Number</th>
            <th className="px-4 py-3 text-center">Due Date</th>
            <th className="px-4 py-3 text-center">Subject</th>
            <th className="px-4 py-3 text-center">Subtotal</th>
            <th className="px-4 py-3 text-center">Total</th>
            <th className="px-4 py-3 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>{renderInvoiceEntries()}</tbody>
      </table>

      <AdaptiveModal
        isOpen={postModal}
        onClose={() => setPostModal(false)}
        position={CustomModalPosition.Center}
        title={<p className="dark:text-white">Code</p>}
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="company_name" className="w-[30%]">
                Credit Account Code
              </label>
              <input
                required
                type="text"
                name="creditCode"
                value={creditCode}
                onChange={(e) => setCreditCode(e.target.value)}
                placeholder="Code"
                className="border rounded p-2"
              />
            </div>
          </div>
          <button
              onClick={() => postJournalMutation(postId)}
             className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Post
          </button>
        </form>
      </AdaptiveModal>
    </section>
  );
};

export default Invoice;

import { AxiosInstance, isAxiosError } from "axios";
import { getData, getUserToken, removeData, saveData } from "../utils/localStorage";
import GenericApi from "./genericApi";
import { wait } from "@/utils/utils";
import { AuthResponse } from "@/types/Auth";
import { authStorageUser } from "@/config/constants/StorageKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { RoutesPathList } from "@/config/routes/Routes";

const maxTries = 3;
let tries = 0;
// let isAlreadyFetchingAccessToken = false;

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use((config) => {
        const token = getUserToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response?.status === 401 && window.location.pathname !== RoutesPathList.Login && window.location.pathname !== RoutesPathList.ResetPassword) {
                console.log(`Attempt ${tries + 1} of ${maxTries}`);
                
                if(isAxiosError(error)){
                    console.log({
                        url: error?.config?.url,
                    })
                }
                
            
                tries++;

                try {
                    await wait(3000);
                    // isAlreadyFetchingAccessToken = true;
                    const loginData = getData<AuthResponse>(authStorageUser);
                    const loginResponse = await GenericApi.post<AuthResponse>(ApiRoutes.REFRESH_TOKEN, {
                        refresh: loginData?.refresh
                    });
                    console.log("Token refresh", loginResponse.data)
                    saveData(authStorageUser, {
                        ...loginResponse.data,
                        user: loginData?.user
                    });
                    return axiosInstance.request(error.config);
                } catch (error) {
                    console.log("Retry failed", error);
                    redirectLogin()
                    
                }
            }
            return Promise.reject(error);
        }
    );
}


const redirectLogin = () => {
    window.location.href = '/login';
    removeData(authStorageUser);
    tries = 0;
}
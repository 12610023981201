import { FormFieldProps, FormFieldType, SelectOptionsType } from "@/types/FormFieldsType";
import { FieldValues, Path } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";

export interface FieldProps<T extends FieldValues> extends FormFieldProps<T> {
  inputStyle?: string;
  required: boolean;
  label: string
  labelStyle?: string;
  containerStyle?: string;
  autocomplete?: "on" | "new-password";
  options?: SelectOptionsType[];
  optionClassName?: string;
}

const FormField = <T extends FieldValues>({
  type,
  placeholder,
  name,
  register,
  error,
  valueAsNumber = false,
  inputStyle,
  label,
  required = false,
  autocomplete = "on",
  labelStyle = "block text-sm font-medium text-gray-700 dark:text-gray-300",
  containerStyle = "flex justify-center flex-col w-full",
  options,
  defaultValue,
  readonly = false,
  value,
  multiple = false,
  optionClassName
}: FieldProps<T>) => (
  <Fragment>
    <div className={containerStyle}>
      <label htmlFor={name} className={labelStyle}>{label} {required ? '*' : ''}</label>
      {
        type === FormFieldType.SELECT ? <select
          required={required}
          className={inputStyle}
          value={value}
          multiple={multiple}
          defaultValue={typeof defaultValue === 'string' ? defaultValue : ''}
          {...register(name as Path<T>, { valueAsNumber })}

        >
          {options?.map((option, index) => (
            <option className={optionClassName ?? ''} key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
          :
          type === FormFieldType.CHECKBOX ? 
          <input
            className={inputStyle}
            type={type}
            readOnly={readonly}
            defaultChecked={defaultValue as boolean ?? false}
            required={required}
            placeholder={placeholder}
            autoComplete={autocomplete}
            {...register(name as Path<T>, { valueAsNumber })}
        /> :
          <input
            className={inputStyle}
            type={type}
            value={value}
            readOnly={readonly}
            defaultValue={typeof defaultValue === 'string' ? defaultValue : ''}
            required={required}
            placeholder={placeholder}
            autoComplete={autocomplete}
            {...register(name as Path<T>, { valueAsNumber })}
          />
      }

      {error && <span className="error-message mt-1 text-red-400">{error.message}</span>}
    </div>
  </Fragment>
);
export default FormField;
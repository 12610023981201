import Logo from '@/components/Logo';
import { RoutesPathList } from '@/config/routes/Routes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// const Pay: React.FC = () => {
//   return (
//     <div>
//       <section>
//         <div className="product" style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="14px"
//             height="16px"
//             viewBox="0 0 14 16"
//             version="1.1"
//           >
//             <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//               <g id="0-Default" transform="translate(-121.000000, -40.000000)" fill="#E184DF">
//                 <path d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z" />
//               </g>
//             </g>
//           </svg>
//           <div className="description" style={{ marginLeft: '15px' }}>
//             <h3>Subscription to Starter plan successful!</h3>
//           </div>
//         </div>

    
//       </section>
//     </div>
//   );
// };

// export default Pay;

const Pay: React.FC = () => {

  const navigate = useNavigate();

  return (
    <div className="h-screen dark:bg-gray-800 flex flex-col items-center justify-center overflow-hidden">
      <Logo size={{ width: '200px', height: '70px' }} className="mb-5" />
      <h1 className="text-3xl dark:text-white font-bold mb-4">Subscription to Starter plan successful!</h1>
      {/* <p className="text-gray-600 dark:text-gray-200 mb-8">Please try again later.</p> */}
      <button
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        onClick={() => navigate(RoutesPathList.CompanyDasboard)}
      >
        Go to dashboard
      </button>
    </div>
  );
}

export default Pay;
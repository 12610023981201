import { z, ZodSchema } from "zod";
import { Company } from "./Company";
import { DoubleEntryAccountItem } from "./DoubleEntry";

export type Item = {
    id: number;
    company: number;
    type: "product" | "service";
    name: string;
    description?: string;
    sale_price: string;  
    income_account?: number | null;  
    expense_account?: number | null;  
}

export type ItemCreateRequest = {
    type: "product" | "service";
    name: string;
    description?: string;
    sale_price: string;  
    income_account?: number | null;  
    expense_account?: number | null;  
}

export type ItemUpdate = {
    type: "product" | "service";
    name: string;
    description?: string;
    sale_price: string;  
    income_account?: number | null;  
    expense_account?: number | null;  
}

export const ItemCreateSchema: ZodSchema<ItemCreateRequest> = z.object({
    type: z.enum(["product", "service"]),
    name: z.string().min(1),
    description: z.string().optional(),
    sale_price: z.string(),
    income_account: z.number().nullable().optional(),
    expense_account: z.number().nullable().optional()
  });

  export const ItemUpdateSchema: ZodSchema<ItemUpdate> = z.object({
    type: z.enum(["product", "service"]),
    name: z.string().min(1),
    description: z.string().optional(),
    sale_price: z.string(),
    income_account: z.number().nullable().optional(),
    expense_account: z.number().nullable().optional()
  });
  
  

  export type ItemType = {
    id: number;
    type: string;
    name: string;
    description: string;
    sale_price: string;
    company: Company;
    income_account: DoubleEntryAccountItem;
    expense_account: DoubleEntryAccountItem;
};
import React, { Fragment, useState } from 'react'
import GenericApi from '@/api/genericApi'
import { DoubleEntryTaxQueryKey } from '@/config/constants/QueryKeys'
import { ApiRoutes } from '@/config/routes/ApiRoutes'
import { ApiResponse, ApiError } from '@/types/Api'
import { FormError, ValidationErrors } from '@/types/ValidationError'
import CustomLogger from '@/utils/CustomLogger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackBarAlert } from '@/hooks/useSnackbar'
import { AxiosError, isAxiosError } from 'axios'
import { CustomModalPosition } from '@/types/Modal'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { generateDoubleEntryTaxUpdateInputs } from '@/utils/generateInputs'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { DoubleEntrySchemaUpdateTax, DoubleEntryTax, DoubleEntryTaxUpdate } from '@/types/DoubleEntryAccountTax'
import AdaptiveModal from './AdaptiveModal'
import LoadingSpinner from './LoadingSpinner'
import FormField from './CustomForm/FormField'

export interface DoubleEntryItemProps {
    doubleEntryAccount: DoubleEntryTax
    edit_accounting_and_payroll: boolean | null
}



const DoubleEntryItemTax: React.FC<DoubleEntryItemProps> = ({ doubleEntryAccount,edit_accounting_and_payroll }) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DoubleEntryTaxUpdate>({
        resolver: zodResolver(DoubleEntrySchemaUpdateTax),
    });

    const [errorAccount, setErrorAccount] = useState<string | null>(null)

    console.log({
        errors,
        doubleEntryAccount,
    })

    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const selectedCompany = useSelector((state: RootState) => state.company.selectedCompany)

    const deleteDoubleEntryMutation = useMutation<ApiResponse<{ message: string }>, ApiError<ValidationErrors>, { id: number }>(
        {
            mutationFn: (data) => GenericApi.delete<{ message: string }>(`${ApiRoutes.DOUBLE_ENTRY_TAX}/${data.id}`),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                queryClient.invalidateQueries({ queryKey: [DoubleEntryTaxQueryKey] })
                showSnackBar("Double Entry Account deleted", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error deleting Double Entry Account", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            setErrorAccount(error?.response?.data?.detail ?? 'Something went wrong')
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const handleDelete = (id: number) => {
        deleteDoubleEntryMutation.mutate({ id })
    }

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const updateDoubleEntryAccountMutation = useMutation<ApiResponse<DoubleEntryTaxUpdate>, ApiError<ValidationErrors>, DoubleEntryTaxUpdate>(
        {
            mutationFn: (data) => GenericApi.put<DoubleEntryTax>(`${ApiRoutes.DOUBLE_ENTRY_TAX}/${doubleEntryAccount.id}`, data),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                setEditModalOpen(false)
                queryClient.invalidateQueries({ queryKey: [DoubleEntryTaxQueryKey] })
                showSnackBar("Double Entry Account updated", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error updating Double Entry Account", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const updateDoubleEntryAccountInputs = generateDoubleEntryTaxUpdateInputs(doubleEntryAccount)

    const onSubmit = (data: DoubleEntryTaxUpdate) => {
        if (selectedCompany) {
            setErrorAccount(null)
            const payload = {
                ...data,
                company_id: selectedCompany.id,
            }
            updateDoubleEntryAccountMutation.mutate(payload)
        }
    }

    return (
        <Fragment>
            <tr
                className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
                <th
                    scope="row"
                    className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                    {doubleEntryAccount.account_code}
                </th>
                <td className="px-4 py-3 text-center">{doubleEntryAccount.name}</td>
                <td className="px-4 py-3 text-center">{doubleEntryAccount.code}</td>
                <td className="px-4 py-3 text-center">{doubleEntryAccount.type}</td>
                
                <td className="px-4 py-3 text-right">
                    <button
                        onClick={toggleEditModal}
                        className="font-medium text-green-400 dark:text-green-400 hover:underline"
                        disabled={!edit_accounting_and_payroll}
                    >
                        Edit
                    </button>
                    <span className="mx-2">|</span>
                    <button
                        disabled={!edit_accounting_and_payroll}
                        onClick={toggleDeleteModal}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                    >
                        Delete
                    </button>

                </td>
            </tr>


            <AdaptiveModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delete Account</h2>}
                position={CustomModalPosition.Center}
                footer={
                    <Fragment>
                        <button
                            onClick={() => handleDelete(doubleEntryAccount.id)}
                            className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
                        >
                            {
                                deleteDoubleEntryMutation.isPending ? <LoadingSpinner variant='danger' /> : 'Delete'
                            }
                        </button>
                        <button
                            onClick={toggleDeleteModal}
                            className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            >
                <p className="text-gray-700 dark:text-gray-300">
                    Are you sure you want to delete account?
                </p>
            </AdaptiveModal>


            <AdaptiveModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Edit Account</h2>}
                position={CustomModalPosition.Center}
            >
                {
              updateDoubleEntryAccountMutation.isError ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">{
                                    errorAccount
                                }</span>
                            </div> : null
                        }
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        updateDoubleEntryAccountInputs.map(input => (
                            <FormField<DoubleEntryTaxUpdate>
                                key={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                required={input.required}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                label={input.label}
                                defaultValue={input?.defaultValue ?? ''}
                                register={register}
                                options={input.options}
                                error={errors[input.name as keyof DoubleEntryTaxUpdate]}
                            />
                        ))
                    }
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            updateDoubleEntryAccountMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Edit Account"
                        }
                    </button>
                </form>
            </AdaptiveModal>
        </Fragment>
    )
}

export default DoubleEntryItemTax;

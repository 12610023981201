import { ZodSchema, z } from "zod";

type TextOptions = 'Input' | 'Output' | 'Reverse';

export type DoubleEntryTax = {
  id: number; 
  company: number;  
  rate: number;       
  name: string;
  account_code: string;
  code: string;
  type: TextOptions; 
}

export type DoubleEntryTaxRequest = {
    rate: number;       
    name: string;
    account_code: string;
    code: string;
    type: TextOptions; 
}

export type DoubleEntryTaxUpdate = {
    rate: number;       
    name: string;
    account_code: string;
    code: string;
    type: TextOptions; 
}

export const DoubleEntryCreateTaxSchema: ZodSchema<DoubleEntryTaxRequest> = z.object({
    rate: z.number(),
    name: z.string(),
    account_code: z.string(),
    code: z.string(),
    type: z.enum(['Input', 'Output', 'Reverse'])
})

export const DoubleEntrySchemaUpdateTax: ZodSchema<DoubleEntryTaxUpdate> = z.object({
    rate: z.number(),
    name: z.string(),
    code: z.string(),
    account_code: z.string(),
    type: z.enum(['Input', 'Output', 'Reverse'])
})
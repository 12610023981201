export enum ApiRoutes {
    LOGIN = "/token/pair",
    REGISTER = "/users/create-organization",
    UPDATE_USER = "/users",
    USERS = "/users",
    REFRESH_TOKEN = "/token/refresh",
    ORGANIZATIONS = "/organization",
    PASSWORD = "/change-password",
    RESET_PASSWORD_EMAIL = "/reset-password-email/email",
    RESET_PASSWORD = "/reset-password-email/password",
    COMPANY = "/company",
    CHANGE_EMAIL = "/users/change-email",
    COUNTRIES = "/countries",
    PERMISSIONS = "/view-permissions",
    CURRENCIES = "/currencies",
    INVITE_USER = "/invitations",
    CREATE_USER = "/users/create-user",
    DOUBLE_ENTRY = "/double-entry-account",
    DOUBLE_ENTRY_TAX = "/double-entry-account-tax",
    ITEMS = "/items",
    ACCOUNTS = '/accounts',
    TAXES = "/tax",
    CONTACTS = "/contact",
    DEPARTMENTS = '/department',
    EXCHANGERATES = '/exchange-rate',
    CATEGORIES = '/category',
    EMPLOYEES = '/employee',
    TRANSFERS = '/transfers',
    RECONCILIATIONS = '/reconciliations',
    TRANSACTIONS = '/transactions',
    DOCUMENTS = '/document',
    PAGES = '/page',
    SALARY = '/salary',
    TRIALBALANCE2COLUMNS = '/reports',
    JOURNALS = '/double-entry-journal',
    
}

export const composeIdRoute = (route: ApiRoutes, id: number | null) => {
    if(!id) {
        return route
    }
    return `${route}/${id}`
}

export const composeRoute = (firstRoute: ApiRoutes, id: number | null, secondRoute: ApiRoutes) => {
    return id ? `${firstRoute}/${id}${secondRoute}` : firstRoute
}


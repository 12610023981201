
import { useMutation } from '@tanstack/react-query';
import { ApiError, ApiResponse } from '@/types/Api';
import { AuthResponse, LoginRequest, LoginRequestSchema } from '@/types/Auth';
import GenericApi from '@/api/genericApi';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { Link, useNavigate } from 'react-router-dom';
import { wait } from '@/utils/utils';
import { RoutesPathList } from '@/config/routes/Routes';
import CustomLogger from '@/utils/CustomLogger';
import { useForm } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import FormField from '@/components/CustomForm/FormField';
import { FormFieldType } from '@/types/FormFieldsType';
import { FormError, ValidationErrors } from '@/types/ValidationError';
import { AxiosError, isAxiosError } from 'axios';
import { formatError } from '@/utils/formatError';
import { removeData, saveData } from '@/utils/localStorage';
import { authStorageUser, companyStorageKey } from '@/config/constants/StorageKeys';
import { Logo } from '@/components';
import { useState } from 'react';


const Login: React.FC = () => {

  const navigate = useNavigate()
  
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevVisibility) => !prevVisibility);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginRequest>({
    resolver: zodResolver(LoginRequestSchema),
  });


  const loginMutation = useMutation<ApiResponse<AuthResponse>, ApiError<ValidationErrors>, LoginRequest>(
    {
      mutationFn: (data) => GenericApi.post<AuthResponse>(ApiRoutes.LOGIN, {
        ...data
      }),
      onSuccess: async ({ data }) => {
        await wait(1000)
        saveData(authStorageUser, data)
        console.log(data)
        removeData(companyStorageKey)
        navigate(RoutesPathList.CompanyDasboard, { replace: true })
      },
      onError: async ({ error }) => {
        CustomLogger.error(error)
        handleAxiosError(error)
      }
    }
  )


  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error)) {
      setError('root', {
        type: 'manual',
        message: error.response?.data?.detail ?? 'An error occurred while logging in'
      })
      return
    }

    if (isAxiosError<ValidationErrors>(error)) {
      setError('root', {
        type: 'manual',
        message: formatError(error, "An error occurred while logging in")
      })
      return
    }

    //* Set error message from server
    setError('root', {
      type: 'manual',
      message: error?.message ?? 'An error occurred while logging in'
    })
  }

  const handleLogin = (loginRequest: LoginRequest) => {
    const { email, password } = loginRequest
    loginMutation.mutate({ email, password })
  }

  const onSubmit = async (data: LoginRequest) => {
    handleLogin(data)
  }



  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-[100dvh]">
      <div className="max-w-screen-xl px-4 py-8 mx-auto">
        <div onClick={() => navigate(RoutesPathList.Landing)} className="mb-6 cursor-pointer text-center">
          <Logo size={{
            width: "200px",
            height: "100px" 
          }}/>
        </div>
        <div className="w-full col-span-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 md:mt-0 sm:max-w-lg xl:p-0">
          <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2xl dark:text-white">
              Welcome back
            </h1>
            {
              loginMutation.isError ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <span className="block sm:inline">{
                  errors.root?.message
                }</span>
              </div> : null
            }
            {
              loginMutation.isSuccess ?
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                  <span className="block sm:inline">Login successful!</span>
                </div> : null
            }
            <form className="space-y-4 lg:space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <FormField
                  label='Email'
                  required={true}
                  error={errors.email}
                  name="email"
                  placeholder='Email'
                  register={register}
                  type={FormFieldType.EMAIL}
                  valueAsNumber={false}
                  inputStyle='bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                />
              </div>
              <div>
                <FormField
                label='Password'
                  required={true}
                  error={errors.password}
                  name="password"
                  placeholder='Password'
                  register={register}
                  type={isPasswordVisible ? FormFieldType.TEXT : FormFieldType.PASSWORD}
                  valueAsNumber={false}
                  inputStyle='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <FormField type={FormFieldType.CHECKBOX}
                      name='remember'
                      placeholder=''
                      label=''
                      register={register}
                      valueAsNumber={false}
                      required={false}
                      error={undefined}
                      inputStyle="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800 transition duration-150 ease-in-out" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                  </div>
                </div>
                <Link to={RoutesPathList.ResetPasswordEmail} className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</Link>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                   <input 
                    checked={isPasswordVisible}
                    onChange={togglePasswordVisibility}
                   className='"w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800 transition duration-150 ease-in-out"' type="checkbox" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Show Password</label>
                  </div>
                </div>
              </div>
              
              <button type="submit" className="w-full flex justify-center items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                {
                  loginMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Sign in to your account"
                }
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet? <Link to={RoutesPathList.Register} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up here</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { ZodType, z } from "zod";

export type SalaryType = {
    id: number;
    first_name: string;
    last_name: string;
    gross_salary: string;
    id_no: string;
    tax_id_no: string;
    si_no: string;
    hours_per_month: number;
    address: string;
    hired_at: string;
    salary_13th: boolean;
}

export type CreateSalaryType = {
    first_name: string;
    last_name: string;
    gross_salary: string;
    id_no: string;
    tax_id_no: string;
    si_no: string;
    hours_per_month: number;
    address: string;
    hired_at: string;
    salary_13th: boolean;
}

export const CreateSalaryTypeSchema: ZodType<CreateSalaryType> = z.object({
    first_name: z.string().min(1, {
      message: "Too short first name",
    }),
    last_name: z.string().min(1, {
      message: "Too short last name",
    }),
    gross_salary: z.string().min(1, {
      message: "Wrong input",
    }),
    id_no: z.string().min(1, {
      message: "Wrong input",
    }),
    tax_id_no: z.string().min(1, {
      message: "Wrong input",
    }),
    si_no: z.string().min(1, {
      message: "Wrong input",
    }),
    hours_per_month: z.number(),
    address: z.string(),
    hired_at: z.string(),
    salary_13th: z.boolean()
  });